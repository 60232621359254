import { Badge } from "@/components/ui/badge";
import { LOGO_ISOTIPO, THEME, WIDTH_LG } from "@constants/global";
import ImageLogoFontsDark from "@public/svg/logo/logo-fonts-dark-v2.svg";
import ImageLogoFontsLight from "@public/svg/logo/logo-fonts-light-v2.svg";
import SVG from "components/SVG";
import { useTemplateContext } from "context/template/TemplateContext";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import { useTheme } from "next-themes";
import Image from "next/image";
import toast from "react-hot-toast";

import { useWindowWidth } from "@react-hook/window-size";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { PRIVATE_ROUTES } from "routes/routes";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { TemplateType } from "stores/template/useTemplateStore";
import { ICONS_MODULES_PATHS } from "../../body/content/utils/iconModulesPath";

interface LogoGroowProps {
  readonly isImagotipo?: boolean;
  readonly render?: boolean;
  readonly redirect?: boolean;
  readonly dark?: boolean;
  readonly marginLeft?: string;
}

export default function LogoGroow(props: LogoGroowProps) {
  const router = useRouter();
  const { theme } = useTheme();
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));
  const { isImagotipo = true, render = true, redirect = true, dark } = props;
  const { t } = useTranslation("common");
  const { template } = useTemplateContext();
  const { vendor } = useVendor();
  const wWidth = useWindowWidth();
  const isLg = wWidth >= WIDTH_LG;

  const { isExpanded: isExPandedSidebar } = useDesktopSidebarStore();

  function handleRedirect(path: string) {
    if (!redirect) return;
    router.push(path);
  }

  if (!render) return null;

  const isAurora = template === TemplateType.Aurora;
  const isClassicTemplate = TemplateType.Classic;

  const handleCopyId = () => {
    if (!user?.isi) return;
    navigator.clipboard.writeText(user?.selectedVendorId || "");
    toast.success(t("ID Copiado"));
  };

  return (
    <div className="flex flex-row items-center">
      <div className="flex flex-row justify-items-start">
        <picture
          style={{
            display: "grid",
            alignContent: "center",
            width: "auto",
            minWidth: isImagotipo ? "34px" : "40px",
            marginLeft: isImagotipo
              ? isAurora && isExPandedSidebar
                ? "4px"
                : props.marginLeft
                  ? props.marginLeft
                  : "24px"
              : isClassicTemplate
                ? "24px"
                : "22px",
            transition: "margin-left 0.3s ease-in-out",
          }}
        >
          <Image
            className="cursor-pointer"
            src={LOGO_ISOTIPO}
            width={40}
            height={40}
            onClick={() => handleRedirect(PRIVATE_ROUTES.explore.path)}
            alt="logo"
          />
        </picture>
      </div>
      <div className="flex flex-row items-start">
        {isImagotipo && (
          <div className="flex flex-col">
            <div>
              <picture
                style={{
                  position: "relative",
                  display: "grid",
                  alignContent: "top",
                  width: "100%",
                  height: "22.53px",
                  minWidth: "119px",
                  marginLeft: "4px",
                }}
              >
                <Image
                  className="absolute top-1 cursor-pointer"
                  src={
                    theme === "dark" || dark
                      ? ImageLogoFontsDark
                      : ImageLogoFontsLight
                  }
                  width={
                    theme === "dark"
                      ? ImageLogoFontsDark.width
                      : ImageLogoFontsLight.width
                  }
                  height={
                    theme === "dark"
                      ? ImageLogoFontsDark.height
                      : ImageLogoFontsLight.height
                  }
                  onClick={() => handleRedirect(PRIVATE_ROUTES.explore.path)}
                  alt="logo fonts"
                />
              </picture>
            </div>
            <div
              className="mt-1 flex cursor-pointer flex-row justify-start align-bottom"
              onClick={handleCopyId}
            >
              <span
                className={`ml-1.5 pt-1 align-bottom font-semibold lg:line-clamp-1 ${
                  theme === THEME.dark
                    ? "text-gray-300"
                    : "text-muted-foreground"
                }`}
                style={{ fontSize: `${isLg ? "0.6rem" : "0.5rem"}` }}
              >
                {vendor?.name}
              </span>
              {isLg && (
                <SVG
                  src={ICONS_MODULES_PATHS.JOBYFINE.BUILDING2}
                  width={13}
                  height={13}
                  className="ml-1"
                />
              )}
              {vendor?.activePlan && (
                <Badge variant="plans" className="ml-1 mt-0.5 px-1 py-0">
                  <span style={{ fontSize: `${isLg ? "0.6rem" : "0.5rem"}` }}>
                    {vendor?.isFullAccessEnabled
                      ? "Enterprise"
                      : vendor?.activePlan}
                  </span>
                </Badge>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
